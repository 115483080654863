<template>

  <div class="page-content">
    <div class="container-fluid">

      <Breadcrumb
          :pgTitle="$t('nav.onlineCourses')"
          :pgIcon="'bx bx-video-recording'"
          :refs="refs"
          :pgCurrent="pgCurrent"
          :addNew="false">
      </Breadcrumb>

      <div class="row">

        <Loading
            v-if="pgLoading">
        </Loading>

        <div v-if="!pgLoading" class="col-8">

          <FormElements
              :label="$t('view.course_info')"
              :labelTitle="$t('view.course_title')"
              :labelBody="$t('view.course_body')"

              :hasTitle=true
              :title_ar="row.title_ar"
              :title_en="row.title_en"

              :hasBody=true
              :body_ar="row.body_ar"
              :body_en="row.body_en"

              v-on:formChange="handleFormChange">
          </FormElements>

        </div>

        <div v-if="!pgLoading" class="col-4">

          <CardLecturer
              v-if="auth.role == 'root'"
              :lecturer_id="row.lecturer_id"
              v-on:lecturerChange="handleLecturerChange">
          </CardLecturer>

          <CardImage
              :labelImage="$t('view.course_image')"
              :image_base64="row.image_baes64"
              :image_preview="row.image_preview"

              v-on:imageChange="handleImageChange">
          </CardImage>

          <CardInput
              :label="$t('app.intro_youtube_link')"
              :input="row.intro_link"
              :type="'text'"
              v-on:inputChange="handleIntroLinkChange">
          </CardInput>

          <!-- <CardInput
              :label="$t('app.youtube_link')"
              :input="row.course_link"
              :type="'text'"
              v-on:inputChange="handleCourseLinkChange">
          </CardInput> -->

          <CardMultiInput
              :label="$t('app.youtube_links')"
              :videos="row.videos"
              :videos_key="row.videos_key"
              :videos_link="row.videos_link"
              :videos_title="row.videos_title"
              :videos_title_en="row.videos_title_en"
              v-on:multiChange="handleCourseMultiLinkChange">
          </CardMultiInput>

          <CardInput
              :label="$t('app.date')"
              :input="row.date"
              :type="'date'"
              v-on:inputChange="handleDateChange">
          </CardInput>

          <CardInput
              :label="$t('app.time')"
              :input="row.time"
              :type="'time'"
              v-on:inputChange="handleTimeChange">
          </CardInput>

          <CardInput
              :label="$t('app.period_per_minutes')"
              :input="row.period"
              :type="'number'"
              v-on:inputChange="handlePeriodChange">
          </CardInput>

          <CardInput
              :label="$t('view.course_price')"
              :input="row.price"
              :type="'number'"
              v-on:inputChange="handlePriceChange">
          </CardInput>

          <CardInput
              :label="$t('app.sort')"
              :input="row.sort"
              :type="'number'"
              v-on:inputChange="handleSortChange">
          </CardInput>

          <CardStatus
              :status="row.status"
              v-on:statusChange="handleStatusChange">
          </CardStatus>

        </div>
      </div>


      <Buttons
          v-if="!pgLoading"
          :btnLoading="btnLoading"
          :btnCurrent="btnCurrent"

          v-on:submitClicked="handelSubmit"
          v-on:cancelClicked="handleCancel">
      </Buttons>

    </div>
  </div>

</template>

<script>
import iziToast from 'izitoast';

export default {
  name: 'EditOrNew',
  components: {
    Breadcrumb: () => import('@/components/Breadcrumb.vue'),
    Loading: () => import('@/components/Loading.vue'),
    FormElements: () => import('@/components/FormElements.vue'),
    CardLecturer: () => import('@/components/CardLecturer.vue'),
    CardImage: () => import('@/components/CardImage.vue'),
    CardInput: () => import('@/components/CardInput.vue'),
    CardMultiInput: () => import('@/components/CardMultiInput.vue'),
    CardStatus: () => import('@/components/CardStatus.vue'),
    Buttons: () => import('@/components/Buttons.vue')
  },
  data() {
    return {
      // auth
      auth: {
        role: '',
        access_token: '',
      },

      // row
      row: {
        title_ar: '',
        title_en: '',

        body_ar: '',
        body_en: '',

        image_base64: '',
        image_preview: '',

        intro_link: '',
        course_link: '',

        lecturer_id: '',

        videos: [],
        videos_key: 1,
        videos_link: [],
        videos_title: [],
        videos_title_en: [],

        date: '',
        time: '',
        period: '60',
        price: '0.00',
        status: 1,
        sort: '',
      },

      msgCurrent: (this.$route.params.id) ? 'Updated' : 'Created',
      btnCurrent: (this.$route.params.id) ? 'Update' : 'Create',
      methodType: (this.$route.params.id) ? 'PUT' : 'POST',
      pgLoading: (this.$route.params.id) ? true : false,
      pgCurrent: (this.$route.params.id) ? 'Edit' : 'Add New',
      btnLoading: false,

      refs: 'onlineCourses',
    }
  },
  mounted() {
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem('access_token')) {
      this.auth.access_token = localStorage.getItem('access_token');
    }
    if (localStorage.getItem('role')) {
      this.auth.role = localStorage.getItem('role');
    }

    if (this.$route.params.id) {
      this.fetchRow();
    }
  },
  methods: {

    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        'Authorization': `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + '/' + this.refs + '/' + this.$route.params.id,
        method: 'GET',
        data: {}
      }
      this.axios(options)
          .then(res => {
            this.pgLoading = false;

            this.row.image_preview = (res.data.row.image) ? res.data.row.image.url : '';
            this.row.image_base64 = (res.data.row.image) ? res.data.row.image.url : '';

            this.row.title_ar = res.data.row.ar.title;
            this.row.title_en = res.data.row.en.title;

            this.row.body_ar = res.data.row.ar.body;
            this.row.body_en = res.data.row.en.body;

            this.row.intro_link = res.data.row.intro_link;
            this.row.course_link = res.data.row.course_link;

            this.row.lecturer_id = res.data.row.lecturer_id;
            this.row.sort = res.data.row.sort;

            // videos
            this.row.videos_key = res.data.row.videos_key;
            if (res.data.row.videos) {
              for (let i = 1; i <= this.row.videos_key; i++) {
                let x = i - 1;
                this.row.videos_link[i] = (res.data.row.videos[x])
                    ? res.data.row.videos[x].course_link
                    : '';
                this.row.videos_title[i] = (res.data.row.videos[x])
                    ? res.data.row.videos[x].title
                    : '';
                this.row.videos_title_en[i] = (res.data.row.videos[x])
                    ? res.data.row.videos[x].title_en
                    : '';
              }
            }

            this.row.date = res.data.row.date;
            this.row.time = res.data.row.time;
            this.row.period = res.data.row.period;

            this.row.price = res.data.row.price;
            this.row.status = res.data.row.status;
          })
          .catch(err => {

            // 403 Forbidden
            if (err.response && err.response.status == 401) {
              this.clearLocalStorage();
              this.$router.push({name: 'login'});
            } else if (err.response && err.response.status == 403) {
              this.$router.push({name: 'forbidden'});
            } else {
              this.btnLoading = false;
              iziToast.warning({
                icon: 'bx bx-angry',
                title: '',
                message: (err.response) ? err.response.data.message : '' + err
              });
            }

          })
          .finally(() => {
          });
    },


    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        'Authorization': `Bearer ` + this.auth.access_token,
      };

      const config = {headers: {'Content-Type': 'multipart/form-data'}};
      const options = {
        url: (this.$route.params.id) ? window.baseURL + '/' + this.refs + '/' + this.$route.params.id : window.baseURL + '/' + this.refs,
        method: this.methodType,
        data: {

          ar: {
            title: this.row.title_ar,
            body: this.row.body_ar
          },

          en: {
            title: this.row.title_en,
            body: this.row.body_en
          },

          image_base64: this.row.image_base64,

          lecturer_id: this.row.lecturer_id,
          sort: this.row.sort,

          intro_link: this.row.intro_link,
          course_link: this.row.course_link,
          videos: this.row.videos,
          date: this.row.date,
          time: this.row.time,
          period: this.row.period,
          price: this.row.price,
          status: this.row.status
        }
      }
      this.axios(options, config)
          .then(() => {
            this.btnLoading = false;
            iziToast.success({
              icon: 'bx bx-wink-smile',
              title: '',
              message: (this.btnCurrent == 'Update') ? 'تم التعديل بنجاح' : 'تم الإضافة بنجاح'
            });

            this.$router.push({name: this.refs});
          })
          .catch(err => {

            // 403 Forbidden
            if (err.response && err.response.status == 401) {
              this.clearLocalStorage();
              this.$router.push({name: 'login'});
            } else if (err.response && err.response.status == 403) {
              this.$router.push({name: 'forbidden'});
            } else {
              this.btnLoading = false;
              iziToast.warning({
                icon: 'bx bx-angry',
                title: '',
                message: (err.response) ? err.response.data.message : '' + err
              });
            }
          })
          .finally(() => {
          })

    },


    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('avatar');
      localStorage.removeItem('username');
      localStorage.removeItem('encrypt_id');
      localStorage.removeItem('role');
    },


    // Cancel
    handleCancel() {
      if (confirm('هل انت متأكد؟')) {
        this.$router.push({name: this.refs});
      }
    },


    // handel Changes from child components
    handleFormChange(event) {
      this.row.title_ar = event.title_ar;
      this.row.title_en = event.title_en;

      this.row.body_ar = event.body_ar;
      this.row.body_en = event.body_en;
    },

    handleLecturerChange(event) {
      this.row.lecturer_id = event.input;
    },

    handleImageChange(event) {
      this.row.image_base64 = event.image_base64;
    },

    handleIntroLinkChange(event) {
      this.row.intro_link = event.input;
    },

    // handleCourseLinkChange(event) {
    //     this.row.course_link = event.input;
    // },
    handleSortChange(event) {
      this.row.sort = event.input;
    },
    handleCourseMultiLinkChange(event) {
      this.row.videos = event.videos;
      this.row.videos_key = event.videos_key;
    },

    handleDateChange(event) {
      this.row.date = event.input;
    },

    handleTimeChange(event) {
      this.row.time = event.input;
    },

    handlePeriodChange(event) {
      this.row.period = event.input;
    },

    handlePriceChange(event) {
      this.row.price = event.input;
    },

    handleStatusChange(event) {
      this.row.status = event.status;
    },

  },
}
</script>
